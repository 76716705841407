import { Observable } from 'rxjs';
import { FakeRepository } from '@drip/core/data-access';
import { IUserTokenRepository } from './IUserTokenRepository';
import { ITokenUser } from '@app-models';

const fakeUsers: ITokenUser[] = [
  {
    accessToken: 'abc123',
  }
];

export class FakeUserTokenRepository extends FakeRepository<ITokenUser> implements IUserTokenRepository {
  constructor() {
    super(fakeUsers);
  }

  loginViaToken(token: string): Observable<ITokenUser> {
    return this.getById(token);
  }
}
