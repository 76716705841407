import { DataService } from './DataService';
import { FakeUserTokenRepository } from './repositories/user/FakeUserTokenRepository';
import { FakeShipmentRepository } from './repositories/shipment/FakeShipmentRepository';

export class FakeApiDataService extends DataService {
  constructor() {
    super();
    this.user = new FakeUserTokenRepository();
    this.shipments = new FakeShipmentRepository();
  }
}
