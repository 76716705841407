import { Injectable } from '@angular/core';
import { DataService } from '../data-access';
import { BehaviorSubject, first, of, tap } from 'rxjs';
import { ShipmentDto, ShipmentStatus } from '@drip/core/dtos';
import { EntityId } from '@drip/core/types';

@Injectable({
  providedIn: 'root'
})
export class ShipmentDataService {

  private dataLoadingSrc$ = new BehaviorSubject<boolean>(false);
  private dataSrc$ = new BehaviorSubject<ShipmentDto | undefined>(undefined);
  private dataErrorSrc$ = new BehaviorSubject<boolean>(false);

  public dataLoading$ = this.dataLoadingSrc$.asObservable();
  public data$ = this.dataSrc$.asObservable();
  public dataError$ = this.dataErrorSrc$.asObservable();

  constructor(
    private readonly dataService: DataService
  ) {
  }

  /**
   * Checks if data is already loaded
   */
  public hasData() {
    return !!this.dataSrc$.value;
  }

  /**
   * Checks if the shipment is already finished
   */
  public isFinished() {
    const status = this.dataSrc$.value?.currentStatus;
    if (!status) {
      return false;
    }
    return status === ShipmentStatus.finished || status === ShipmentStatus.finishedByFollowingShipment;
  }

  /**
   * Load Shipment data
   */
  public loadData() {
    this.dataLoadingSrc$.next(true);
    this.dataService.shipments.getShipment()
      .pipe(first())
      .subscribe({
        next: result => {
          if (result) {
            this.dataSrc$.next(result);
          }
          this.dataErrorSrc$.next(!result);
        },
        error: () => {
          this.dataSrc$.next(undefined);
          this.dataErrorSrc$.next(true);
        },
        complete: () => {
          this.dataLoadingSrc$.next(false);
        }
      });
  }

  /**
   * Upload File for task
   * @param taskId ID of Task
   * @param file File
   */
  public uploadTaskFile(taskId: EntityId, file: File) {
    const data = this.dataSrc$.value;
    if (!data) {
      return of();
    }
    const stops = data.stops;
    const correctStop = stops?.find(stop => stop.tasks.some(task => task.id === taskId));
    if (!correctStop) {
      return of();
    }
    return this.dataService.shipments.uploadFile(correctStop.id, taskId, file, 'pod')
      .pipe(tap(() => {
        this.loadData();
      }));
  }
}
