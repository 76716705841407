import { Observable } from 'rxjs';
import { AdvancedHttpClient, WebDataRepository } from '@drip/core/data-access';
import { IUserTokenRepository } from './IUserTokenRepository';
import { ITokenUser } from '@app-models';

export class UserTokenRepository extends WebDataRepository<ITokenUser> implements IUserTokenRepository {
  constructor(public override httpClient: AdvancedHttpClient) {
    super(httpClient, '');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  loginViaToken(token: string): Observable<ITokenUser> {
    return this.httpClient.get({url: `/${token}`});
  }
}
