import { ApiClient } from '@drip/core/data-access';
import { UserTokenRepository } from './repositories/user/UserTokenRepository';
import { IOWEnvironment } from '@app-environments/IOWEnvironment';
import { HttpClient } from '@angular/common/http';
import { DataService } from './DataService';
import { ShipmentRepository } from './repositories/shipment/ShipmentRepository';

export class WebApiDataService extends DataService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly environment: IOWEnvironment
  ) {
    super();

    const apiClient = new ApiClient(this.environment.api.baseUrl, this.httpClient);

    this.user = new UserTokenRepository(apiClient);
    this.shipments = new ShipmentRepository(apiClient);
  }
}
