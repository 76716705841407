import { Injectable } from '@angular/core';
import { Geolocation, Position } from '@capacitor/geolocation';
import { BehaviorSubject } from 'rxjs';
import { LocationPosition } from '@app-models';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  private permDeniedSrc$ = new BehaviorSubject<boolean>(false);
  private locationSrc$ = new BehaviorSubject<LocationPosition | null>(null);

  public permDenied$ = this.permDeniedSrc$.asObservable();
  public location$ = this.locationSrc$.asObservable();

  constructor() {
    this.handlePermissions();
  }

  /**
   * Get the current location
   */
  get location() {
    return this.locationSrc$.value;
  }

  /**
   * Pushes a new location to the Stream
   * @param position
   * @private
   */
  private updateLocation(position: Position) {
    this.locationSrc$.next({
      long: position.coords.longitude,
      lat: position.coords.latitude,
      heading: position.coords.heading
    });
  }

  /**
   * Connect to the Geolocation plugin from capacitor and setup listener
   * @private
   */
  private setupLocationTracking() {
    Geolocation.watchPosition({
      maximumAge: 5000,
      enableHighAccuracy: true
    }, (position, err) => {
      if (err) {
        console.log(err);
        this.locationSrc$.error(err);
        return;
      }
      if (position) {
        this.updateLocation(position);
      }
    });
  }

  /**
   * Check Permissions
   *
   * On Web there is no need to explicitly ask for permission
   * @private
   */
  private async handlePermissions() {
    const currentPermissions = await Geolocation.checkPermissions();
    if (currentPermissions.location === 'denied') {
      this.permDeniedSrc$.next(true);
      return;
    }
    this.setupLocationTracking();
    this.permDeniedSrc$.next(false);
  }
}
