import { EntityId } from '@drip/core/types';

export enum ShipmentStopTaskStatus {
  TODO = 'todo',
  IN_PROGRESS = 'in_progress',
  DONE = 'done',
  FAILED = 'failed',
  SKIPPED = 'skipped',
}

export interface DatetimeFCM {
  _nanoseconds: number;
  _seconds: number;
}

export enum ShipmentStopTaskType {
  LOAD = 'load',
  UNLOAD = 'unload',
  REFUELING = 'refueling',    // not part of this version
  BREAK = 'break',            // not part of this version
  SERVICE = 'service',        // not part of this version
  SCAN_DOCUMENT = 'scan_document',
  SCAN_DOCUMENT_COMP = 'scan document'
}

export interface ConsignmentLoadDto {
  amount: number;
  name: string;
  posArt: string;
  weight: number;
}

export interface ShipmentStopTaskDto {
  consignmentId: EntityId;
  endDateTime?: number;
  startDateTime?: number;
  status: ShipmentStopTaskStatus;
  id: EntityId;
  type: ShipmentStopTaskType;
  documentType: string;
  consignmentLoad?: ConsignmentLoadDto;

  messages?: unknown[];
  finishedDatetime?: DatetimeFCM;
}
