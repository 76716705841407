import { EntityId } from '@drip/core/types';
import { BaseModel } from '@drip/core/models';
import { ShipmentStopDto } from './shipment-stop.dto';

export enum AssetType {
  CAR = 'car',
  TRUCK = 'truck',
  TRAILER = 'trailer',
}

export enum AssetPullingStatus {
  PULLING = 'pulling',
  PULLED = 'pulled',
}

export enum ShipmentStatus {
  incomplete = 'incomplete',
  cancelled = 'cancelled',
  pending = 'pending',
  upcoming = 'upcoming',
  inProgress = 'in_progress',
  finished = 'finished',
  finishedByFollowingShipment = 'finished_by_following_shipment',
  archive = 'archive',
  error = 'error',
  actionRequired = 'actionRequired',
  tooLate = 'tooLate'
}

export interface ShipmentDocumentDto {
  documentId: EntityId;
  fileName: string;
  timestamp: number;
  type: string;
  fileDownloadUrl: string;
  comment?: string;
  filePath: string;
  shipmentDoc?: boolean;
  entityId?: EntityId;
}

export interface ShipmentStatusHistoryDto {
  dateTime: number;
  status: ShipmentStatus;
  statusText: string;
  userType?: string;
}

export interface ShipmentTemperatureControlDto {
  coolingType: string;
  lowerThreshold: string | number;
  setPoint: string | number;
  upperTrehshold: string | number;
  upperThreshold: string | number;
}

export interface ShipmentAssetDto {
  assetId: EntityId;
  activationTime?: number;
  deactivationTime?: number;
  licencePlate: string;
  pulling: AssetPullingStatus;
  suggestedName?: string | number; // suggestedName can be number as well https://d3eif.atlassian.net/browse/DRIP-399
  type: AssetType;
  ownerName: string;
}

export interface ShipmentDto extends BaseModel {
  clientCompanyId?: EntityId;
  calculatedStart?: number;
  calculatedEnd?: number;
  currentStatus?: ShipmentStatus;
  dispatchers?: EntityId[]; // uuids of dispatchers
  documents?: ShipmentDocumentDto[];
  driverIds?: EntityId[]; // uuids of drivers
  id: EntityId;
  messages?: unknown[]; // for now its unknown, we don't use messages
  ptvStartLocation?: unknown;
  plannedRoute?: {
    polylineURL: string;
    totalMeters: number;
    totalSeconds: number;
  };
  ptvUpdateStart?: number;
  shipmentExternalId?: EntityId;
  shipmentId: EntityId;
  statusHistory?: ShipmentStatusHistoryDto[]; // in chronological order, the last one is the actual
  stops: ShipmentStopDto[];

  temperatureControl?: ShipmentTemperatureControlDto;
  tripId?: string;
  vehicle?: {
    assets: ShipmentAssetDto[];
    vehicleProfile: object;
  };
  contractorCompanyId?: EntityId;
  contractorCompanyName?: string;
  contractDate?: number;
  /**
   * Optional field representing number of comments for shipment.
   */
  cntCommentUpdate?: number;
}
