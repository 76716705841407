import { EntityId } from '@drip/core/types';
import { ShipmentStopTaskDto } from './shipment-stop-task.dto';

export enum ShipmentStopStatus {
  UPCOMING = 'upcoming', ARRIVED = 'arrived', LEFT = 'left'
}

export interface ShipmentStopDto {
  address?: {
    city?: string;
    country?: string;
    street?: string;
    houseNumber?: string;
    postalCode?: string;
  };
  consignmentIds: EntityId[];
  coordinates?: { latitude: number; longitude: number };
  id: EntityId;
  name: string;
  arrivalTime?: string;
  leaveTime?: string;
  plannedArrivalTime?: string;
  plannedLeaveTime?: string;
  plannedRoute?: {
    polylineURL: string;
    totalMeters: number;
    totalSeconds: number;
  };
  status?: ShipmentStopStatus;
  //  { status type is string or this
  //   ETA: number;        // unix timestamp?
  //   arrived: number     // unix timestamp?
  //   finished: number;   // unix timestamp?
  //   distancePercentage: number;
  //   distanceToGo: number;
  // };
  tasks: ShipmentStopTaskDto[];
  window?: {
    end: number;
    start: number;
  };
  assetsInfo?: unknown;
}
