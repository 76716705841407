import { Injectable } from '@angular/core';
import { DataService } from '../data-access';
import { BehaviorSubject, first } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private tokenIsLoadingSrc$ = new BehaviorSubject<boolean>(false);
  private isAuthenticatedSrc$ = new BehaviorSubject<boolean>(false);
  private authTokenSrc$ = new BehaviorSubject<string>('');
  private tokenInvalidSrc$ = new BehaviorSubject<boolean>(false);

  public tokenIsLoading$ = this.tokenIsLoadingSrc$.asObservable();
  public isAuthenticated$ = this.isAuthenticatedSrc$.asObservable();
  public authToken$ = this.authTokenSrc$.asObservable();
  public tokenInvalid$ = this.tokenInvalidSrc$.asObservable();

  public get tokenIsLoading() {
    return this.tokenIsLoadingSrc$.value;
  }

  public get isAuthenticated() {
    return this.isAuthenticatedSrc$.value;
  }

  public get authToken() {
    return this.authTokenSrc$.value;
  }

  public get tokenInvalid() {
    return this.tokenInvalidSrc$.value;
  }

  private lastTokenValidated?: string;

  constructor(
    private readonly dataService: DataService
  ) {
  }

  /**
   * Check validity of the Link Token and authenticate user
   * @param token
   */
  public validateToken(token: string) {
    if (this.tokenIsLoading || token === this.lastTokenValidated) {
      return;
    }
    this.tokenIsLoadingSrc$.next(true);
    this.dataService.user.loginViaToken(token)
      .pipe(first())
      .subscribe({
        next: result => {
          if (result.accessToken) {
            this.authTokenSrc$.next(result.accessToken);
            this.isAuthenticatedSrc$.next(true);
            this.tokenInvalidSrc$.next(false);
          }
        },
        error: () => {
          this.tokenInvalidSrc$.next(true);
        },
        complete: () => {
          this.lastTokenValidated = token;
          this.tokenIsLoadingSrc$.next(false);
        }
      });
  }
}
