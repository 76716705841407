import { FakeRepository } from '@drip/core/data-access';
import {
  ShipmentDto,
  ShipmentStatus,
  ShipmentStopStatus,
  ShipmentStopTaskStatus,
  ShipmentStopTaskType
} from '@drip/core/dtos';
import { EntityId } from '@drip/core/types';
import { Observable, of } from 'rxjs';
import { IShipmentRepository } from './IShipmentRepository';

const mockShipment: ShipmentDto[] = [{
  id: 'MOCK-SHIPMENT-1',
  shipmentId: 'MOCK-SHIPMENT-1',
  contractorCompanyId: 'MOCK-COMPANY-1',
  contractorCompanyName: 'MOCK COMPANY INC.',
  shipmentExternalId: 'EXTERNAL-MOCK-ID',
  currentStatus: ShipmentStatus.inProgress,
  stops: [{
    id: 'MOCK-STOP-1',
    name: 'MOCK-STOP-1',
    consignmentIds: ['MOCK-CONSIGNMENT-1', 'MOCK-CONSIGNMENT-2'],
    address: {
      city: 'Linz',
      country: 'Austria',
      houseNumber: '11',
      postalCode: '4040',
      street: 'Wahringerstraße'
    },
    coordinates: {
      longitude: 14.324464,
      latitude: 48.265017
    },
    tasks: [{
      id: 'MOCK-STOP-1-TASK-1',
      consignmentId: 'MOCK-CONSIGNMENT-1',
      status: ShipmentStopTaskStatus.TODO,
      type: ShipmentStopTaskType.LOAD,
      documentType: 'something',
      consignmentLoad: {
        weight: 35,
        name: 'Heavy Packet',
        amount: 1,
        posArt: 'Stk'
      }
    }, {
      id: 'MOCK-STOP-1-TASK-2',
      consignmentId: 'MOCK-CONSIGNMENT-2',
      status: ShipmentStopTaskStatus.TODO,
      type: ShipmentStopTaskType.LOAD,
      documentType: 'something'
    }]
  }, {
    id: 'MOCK-STOP-2',
    consignmentIds: ['MOCK-CONSIGNMENT-1', 'MOCK-CONSIGNMENT-2'],
    'address': {
      'street': 'Schneiderweg',
      'houseNumber': '1',
      'postalCode': '4251',
      'city': 'Sandl',
      'country': 'AT'
    },
    'coordinates': {
      'longitude': 14.642447,
      'latitude': 48.549594
    },
    'name': 'COR GmbH',
    'tasks': [{
      id: 'MOCK-STOP-2-TASK-1',
      consignmentId: 'MOCK-CONSIGNMENT-1',
      status: ShipmentStopTaskStatus.TODO,
      type: ShipmentStopTaskType.LOAD,
      documentType: 'something'
    }]
  }, {
    id: 'MOCK-STOP-3',
    'coordinates': {
      'longitude': 14.258412,
      'latitude': 47.886103
    },
    'name': 'Billy\'s pizzeria',
    consignmentIds: ['MOCK-CONSIGNMENT-1', 'MOCK-CONSIGNMENT-2'],
    address: {
      city: 'Linz',
      country: 'Austria',
      houseNumber: '8',
      postalCode: '4591',
      street: 'Kirchenplatz'
    },
    tasks: [{
      id: 'MOCK-STOP-3-TASK-1',
      consignmentId: 'MOCK-CONSIGNMENT-1',
      status: ShipmentStopTaskStatus.TODO,
      type: ShipmentStopTaskType.UNLOAD,
      documentType: 'something'
    }, {
      id: 'MOCK-STOP-3-TASK-2',
      consignmentId: 'MOCK-CONSIGNMENT-2',
      status: ShipmentStopTaskStatus.TODO,
      type: ShipmentStopTaskType.SCAN_DOCUMENT,
      documentType: 'something'
    }]
  }]
}];

export class FakeShipmentRepository extends FakeRepository<ShipmentDto> implements IShipmentRepository {
  constructor() {
    super(mockShipment);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  uploadFile(stopId: EntityId, taskId: EntityId, file: File, documentType: string): Observable<void> {
    const stops = mockShipment[0].stops;
    const correctStop = stops?.find(stop => stop.tasks.some(task => task.id === taskId));
    const correctTask = correctStop?.tasks.find(task => task.id === taskId);
    if (correctTask) {
      correctTask.status = ShipmentStopTaskStatus.DONE;
    }
    if (correctStop?.tasks.every(task => task.status === ShipmentStopTaskStatus.DONE)) {
      correctStop.status = ShipmentStopStatus.LEFT;
    }
    const allDone = stops?.every(stop => stop.tasks.every(task => task.status === ShipmentStopTaskStatus.DONE));
    if (allDone) {
      this.entities[0].currentStatus = ShipmentStatus.finished;
    }
    return this.delayResponse(of(void 0));
  }

  getShipment(): Observable<ShipmentDto> {
    return this.getById('MOCK-SHIPMENT-1');
  }
}
