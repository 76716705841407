import { IShipmentRepository } from './IShipmentRepository';
import { AdvancedHttpClient, WebDataRepository } from '@drip/core/data-access';
import { ShipmentDto } from '@drip/core/dtos';
import { EntityId } from '@drip/core/types';
import { Observable } from 'rxjs';

export class ShipmentRepository extends WebDataRepository<ShipmentDto> implements IShipmentRepository {
  constructor(public override httpClient: AdvancedHttpClient) {
    super(httpClient, '');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public uploadFile(stopId: EntityId, taskId: EntityId, file: File, documentType: string): Observable<void> {
    const formDataObject = new FormData();
    formDataObject.append('file', file);
    formDataObject.append('documentType', documentType);
    return this.httpClient.post({url: `/stops/${stopId}/tasks/${taskId}/documents`, body: formDataObject })
  }

  public getShipment(): Observable<ShipmentDto> {
    return this.httpClient.get({url: ''});
  }
}
